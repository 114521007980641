






















import { defineComponent } from '@vue/composition-api'

interface Btn {
  label: string
  url?: string
  icon?: Record<string, unknown>
  to?: string
}

export default defineComponent({
  name: 'g-btn',
  props: {
    btn: {
      type: Object as () => Btn,
      default: () => ({} as Btn),
    },
    tag: {
      type: String,
      default: 'router-link',
    },
  },

  setup() {
    return {}
  },
})
