











import { defineComponent, onMounted, onUnmounted, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'popup',
  components: {},
  props: {},

  setup(props, ctx) {
    const onKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        ctx.emit('close')
      }
    }

    const focusablePrime = ref<HTMLElement | null>(null)

    const trapFocus = (element, prevFocusableElement = document.activeElement) => {
      focusablePrime.value = prevFocusableElement as HTMLElement
      console.log('element', element, focusablePrime)

      const focusableEls = Array.from(
        element.querySelectorAll(
          // eslint-disable-next-line max-len
          'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
        )
      )
      // eslint-disable-next-line prefer-destructuring
      const firstFocusableEl = focusableEls[0] as HTMLElement
      const lastFocusableEl = focusableEls[focusableEls.length - 1] as HTMLElement
      let currentFocus: HTMLElement | null = null

      firstFocusableEl.focus()
      currentFocus = firstFocusableEl

      const handleFocus = e => {
        e.preventDefault()
        // if the focused element "lives" in your modal container then just focus it
        if (focusableEls.includes(e.target)) {
          currentFocus = e.target
        } else {
          // you're out of the container
          // if previously the focused element was the first element then focus the last
          // element - means you were using the shift key
          if (currentFocus === firstFocusableEl) {
            lastFocusableEl.focus()
          } else {
            // you previously were focused on the last element so just focus the first one
            firstFocusableEl.focus()
          }
          // update the current focus var
          currentFocus = document.activeElement as HTMLElement
        }
      }

      document.addEventListener('focus', handleFocus, true)
    }

    onMounted(() => {
      window.addEventListener('keyup', onKey)
      document.body.classList.add('no-scroll')

      trapFocus(document.getElementById('popupInner'))
    })

    onUnmounted(() => {
      window.removeEventListener('keyup', onKey)
      document.body.classList.remove('no-scroll')

      focusablePrime.value?.focus()
    })

    return {}
  },
})
