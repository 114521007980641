






import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'g-link',
  props: {
    href: {
      type: String,
      required: false,
    },
    to: {
      type: [String, Object],
      required: false,
    },
    label: String,
  },

  setup(props) {
    const tag = computed(() => {
      const isExternal = props.href ? /http|https|tel|mailto|#/.test(props.to || props.href) : null

      return isExternal ? 'a' : 'router-link'
    })

    const attributes = computed(() => {
      const attr = tag.value === 'a' ? 'href' : 'to'

      return {
        [attr]: props.href || props.to,
        target: attr === 'href' ? '_blank' : 'self',
      }
    })

    return { attributes, tag }
  },
})
