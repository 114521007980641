/* eslint-disable capitalized-comments */
// Webpack imports
import '@/styles/main.scss'

import Vue from 'vue'
import axios, { AxiosTransformer } from 'axios'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const LosslessJSON = require('lossless-json')

import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import * as Sentry from '@sentry/vue'

// Plugins
// import Fragment from 'vue-fragment'
// Vue.use(Fragment.Plugin)
import VueMask from 'v-mask'
Vue.use(VueMask)

import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import hooks from '@u3u/vue-hooks'
Vue.use(hooks)

import VueAware from 'vue-aware'
Vue.use(VueAware)

import VueSvgSprite from 'vue-svg-sprite'
Vue.use(VueSvgSprite, { url: '' })

// Components

// Directives
import VueOutside from '@/inc/directives/outside'
Vue.directive('outside', VueOutside)
import VueSrc from '@/inc/directives/src'
Vue.directive('src', VueSrc)

// Filters
// import VFilters, {
//   VFiltersOptions,
//   capitalize,
//   linebreak,
// } from '@monofront/v-filters'

// Vue.use<VFiltersOptions>(VFilters, { capitalize, linebreak })
// DEV
// import capitalize from '@/inc/filters/capitalize'
// Vue.filter('capitalize', capitalize)
// import lineBreak from '@/inc/filters/linebreak'
// Vue.filter('linebreak', lineBreak)
import modifiers from '@/inc/filters/modifiers'
Vue.filter('modifiers', modifiers)
import numbers from '@/inc/filters/numbers'
Vue.filter('numbers', numbers)
import trimzeros from '@/inc/filters/trimzeros'
Vue.filter('trimzeros', trimzeros)
import slugify from '@/inc/filters/slugify'
Vue.filter('slugify', slugify)

import i18n from '@/inc/i18n'
import { App, AppType } from '@/inc/types'
import { logger, pascalToKebab } from '@/inc/utils'
// DEV
// import AppComponent from '@/App.vue'
// import ErrorComponent from '@/Error.vue'
// import createRouter from '@/router'
// import createStore from '@/store'

logger.info(process.env)

Vue.prototype.$logger = logger
Vue.prototype.$debug = false
Vue.config.productionTip = false

// Import all global components
// Btn.vue => <g-btn></g-btn>
const requireComponent = require.context('@/components/g', true, /.*\.vue$/)

requireComponent.keys().forEach(fileName => {
  let baseComponentConfig = requireComponent(fileName)

  baseComponentConfig = baseComponentConfig.default || baseComponentConfig

  const baseComponentName =
    baseComponentConfig.name || `g-${pascalToKebab(fileName.replace(/^.+\//, '').replace(/\.\w+$/, ''))}`

  Vue.component(baseComponentName, baseComponentConfig)
})

// Export factory function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createApp(ctx: any): App {
  const { type } = ctx as { type: AppType }
  const isApiRe = new RegExp(process.env.VUE_APP_API_PATH as string)

  const initSentry = router =>
    Sentry.init({
      Vue,
      dsn: 'https://9bd23f044e114c6ebaadbeef0a8b0d36@sentry.tools.epic-sys.io/3',
      // eslint-disable-next-line capitalized-comments
      // trackComponents: true,
      integrations: [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            /^https:\/\/myelantis.elantis.be/,
            /^https:\/\/elantis-client-space-app-v1.staging.epic-sys.io/,
            'localhost',
          ],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })

  logger.trace('[main:createApp]', type)

  // Avoid EAN (as bigInt) parsing rounding
  // 541460900000467314 -> 541460900000467300
  function convertLosslessNumber(key, value) {
    if (value && value.isLosslessNumber) {
      // If "lossLess", returns string value
      return value.value
    }

    return value
  }

  axios.defaults.transformResponse = [
    data => {
      if (typeof data === 'string') {
        try {
          // eslint-disable-next-line no-param-reassign
          data = LosslessJSON.parse(data, convertLosslessNumber)
        } catch (e) {
          /* Ignore */
        }
      }

      return data
    },
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
  ]

  // Set default base URL
  if (type === 'client') {
    axios.defaults.baseURL = window.location.origin
    Vue.prototype.$debug = window.location.search.includes('debug')
  }

  // Send ENV cookies to API (staging / branching)
  if (type === 'client' && process.env.VUE_APP_RELEASE === 'staging') {
    axios.interceptors.request.use(config => {
      const { epic_env_back: envBackCookie } = ctx.$cookie.getCookies()

      if (envBackCookie && isApiRe.test(config.url as string)) {
        logger.warn('ENV', config.url, envBackCookie)
        /* eslint-disable-next-line */
        config.headers.epic_env_back = envBackCookie
        // config.headers.cookie = `epic_env_back=${envBackCookie}`
      }

      return config
    })
  }

  // Dayjs
  dayjs.locale('fr')
  Vue.prototype.$dayjs = dayjs

  /* eslint-disable @typescript-eslint/no-var-requires, global-require */
  const AppComponent = require('@/App.vue').default
  const ErrorComponent = require('@/Error.vue').default
  const createRouter = require('@/router').default
  const createStore = require('@/store').default
  /* eslint-enable @typescript-eslint/no-var-requires, global-require */

  const store = createStore(type)
  const router = createRouter(store)

  // if (
  //   process.env.VUE_APP_RELEASE === 'staging' ||
  //   process.env.VUE_APP_RELEASE === 'production'
  // ) {
  initSentry(router)
  // }

  const app = {
    ErrorComponent,
    i18n,
    router,
    store,
    // This is necessary, it is for vue-meta
    head: {
      titleTemplate: '%s',
    },
    // This will expose `$root.$options.$resource` into components (server-side)
    $resource: ctx.$resource,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (h: any) => h(AppComponent),
  }

  return app
}
